import { SelectedCoinDenom } from './wallet-utils'
import { DexConfig } from './page-components/Stake/DexAdapters/types'

// A centralized place to store all query keys. This makes it simpler to look at a glance
// what kind of query keys exists in the app; makes it easier to avoid duplicate query keys.
//
// Loose naming convention is `{domain}-{name}` - this makes it simple to have unique key across pages.
// Query keys that accept arguments should be based off of a base query key so it can be simply invalidated.
// There is no need to be strict on typing the arguments, as long as it's clear what the attributes are.
const queryKeys = {
  globalTvl: ['global-tvl'],
  globalTvlByDenom: ({ denom }: Record<'denom', string>) => ['global-tvl', denom],
  globalTokenValueInUsd: ['global-token-value-in-usd'],
  globalTokenValueInUsdByDenom: ({ denom }: Record<'denom', string>) => ['global-token-value-in-usd', denom],
  globalHostZoneBase: ['global-host-zone'],
  globalHostZoneFromChainId: ({ chainId }: Record<'chainId', string>) => ['global-host-zone', chainId],
  globalApy: ['global-apy'],
  globalRedemptionRecordsBase: ['global-redemption-records'],
  globalRedemptionRecordsByAddress: ({ address }: Record<'address', string>) => ['global-redemption-records', address],
  globalAnnouncementFlags: ['global-announcement-flags'],
  globalRewardsApr: ['global-rewards-apr'],

  airdropClaimHistoryBase: ['airdrop-claim-history'],
  airdropClaimHistoryByAddress: ({ address }: Record<'address', string>) => ['airdrop-claim-history', address],
  airdropRebateProgramBase: ['airdrop-rebate-program'],
  airdropRebateProgramByAddress: ({ address }: Record<'address', string>) => ['airdrop-rebate-program', address],
  airdropClaimRecordBase: ['airdrop-claim-record'],
  airdropClaimRecordByAddress: ({ address }: Record<'address', string>) => ['airdrop-claim-record', address],
  airdropQualifiedChainsBase: ['airdrop-qualified-chains'],
  airdropQualifiedChainsByAddresses: ({ addresses }: { addresses: Record<string, string> }) => [
    'airdrop-qualified-chains',
    addresses
  ],
  airdropLinkStatusBase: ['airdrop-link-status'],
  airdropLinkStatusByAddresses: ({ addresses }: { addresses: Record<string, string> }) => [
    'airdrop-link-status',
    addresses
  ],

  stakingModalDexPoolApyBase: ['staking-modal-dex-pool-apy'],
  stakingModalDexPoolApyByDenom: ({ denom }: Record<'denom', string>) => ['staking-modal-dex-pool-apy', denom],
  stakingStatsOsmosisMarketPriceBase: ['staking-stats-osmosis-market-price'],
  stakingStatsOsmosisMarketPriceByDenom: ({
    denom,
    dexConfig
  }: Record<'denom' | 'dexConfig', string | DexConfig | null>) => [
    'staking-stats-osmosis-market-price',
    denom,
    dexConfig
  ],
  stakingModalAstroportTokenPrices: ['staking-modal-astroport-token-prices'],
  stakingRateLimit: ['staking-rate-limits'],
  stakingRateLimitByDenom: ({ denom }: Record<'denom', string>) => ['staking-rate-limits', denom],

  unstakeFormEpochTrackerBase: ['unstake-form-epoch-tracker'],
  unstakeFormRedemptionRecordBase: ['unstake-form-redemption-record'],
  unstakeFormRedemptionRecord: ({ address, epochNumber }: Record<'address' | 'epochNumber', string>) => {
    return [...queryKeys.unstakeFormRedemptionRecordBase, address, epochNumber]
  },

  stakeFormEligibleAirdropsBase: ['stake-form-eligible-airdrops'],
  stakeFormEligibleAirdropsByAddress: ({ address }: Record<'address', string>) => [
    'stake-form-eligible-airdrops',
    address
  ],

  lsmValidatorsBase: ['lsm-validators'],
  lsmValidatorsByAddress: ({ address }: Record<'address', string>) => ['lsm-validators', address],
  lsmValidatorPersonaBase: ['lsm-validator-persona'],
  lsmValidatorPersonaByAddress: ({ address }: Record<'address', string>) => ['lsm-validator-persona', address],
  lsmLiquidStakeQueryCallbackBase: ['lsm-liquid-stake-query-callback'],
  lsmLiquidStakeQueryCallbackByHash: ({ hash }: Record<'hash', string>) => ['lsm-liquid-stake-query-callback', hash],
  lsmRedelegationValidatorBase: ['lsm-redelegation-validator'],
  lsmRedelegationValidatorByAccount: ({ validators }: { validators: string[] }) => [
    'lsm-redelegation-validator',
    validators
  ],

  chainAccount: ({
    denom,
    strideAddress,
    safeMode
  }: Record<'denom' | 'strideAddress' | 'safeMode', SelectedCoinDenom | string | boolean>) => {
    return ['chainAccount', denom, strideAddress, safeMode]
  },

  selectedWallet: ({ denom, strideAddress }: Record<'denom' | 'strideAddress', SelectedCoinDenom | string>) => {
    return ['selectedWallet', denom, strideAddress]
  },
  dexWallet: ({ denom, strideAddress }: Record<'denom' | 'strideAddress', SelectedCoinDenom | string>) => {
    return ['dexWallet', denom, strideAddress]
  },

  strideBalancesBase: ['strideBalances'],
  strideBalances: ({ denom, strideAddress }: Record<'denom' | 'strideAddress', SelectedCoinDenom | string>) => {
    return ['strideBalances', denom, strideAddress]
  },
  strideBalancesV2Base: ['strideBalancesV2'],
  strideBalancesV2: ({ denom, strideAddress }: Record<'denom' | 'strideAddress', SelectedCoinDenom | string>) => {
    return ['strideBalancesV2', denom, strideAddress]
  },
  selectedBalancesBase: ['selectedBalances'],
  selectedBalances: ({ denom, strideAddress }: Record<'denom' | 'strideAddress', SelectedCoinDenom | string>) => {
    return ['selectedBalances', denom, strideAddress]
  },
  selectedBalancesV2Base: ['selectedBalancesV2'],
  selectedBalancesV2: ({ denom, strideAddress }: Record<'denom' | 'strideAddress', SelectedCoinDenom | string>) => {
    return ['selectedBalancesV2', denom, strideAddress]
  },

  walletAddresses: ({ chains, strideAddress }: Record<'chains' | 'strideAddress', SelectedCoinDenom[] | string>) => {
    return ['walletAddresses', chains, strideAddress]
  },

  transactionHistoryBase: ['transaction-history'],
  transactionHistoryByAddresses: ({ addresses }: { addresses: Record<string, string> }) => [
    'transaction-history',
    addresses
  ],
  transactionHistoryIbcStatusBase: ['transaction-history-ibc-status'],
  transactionHistoryIbcStatusByHash: ({ hash }: Record<'hash', string>) => ['transaction-history-ibc-status', hash],
  transactionHistoryUnbondingsBase: ['transaction-history-unbondings'],
  transactionHistoryUnbondingsByHash: ({ address }: Record<'address', string>) => [
    'transaction-history-unbondings',
    address
  ],
  transactionHistoryLsmLiquidStakeQueryCallbackBase: ['transaction-history-lsm-liquid-stake-query-callback'],
  transactionHistoryLsmLiquidStakeQueryCallbackByHash: ({ hash }: Record<'hash', string>) => [
    'transaction-history-lsm-liquid-stake-query-callback',
    hash
  ],
  transactionHistoryLsmIbcBalancesBase: ['transaction-history-lsm-ibc-balances'],
  transactionHistoryLsmIbcBalancesByAddress: ({ addresses }: { addresses: Record<string, string> }) => [
    'transaction-history-lsm-ibc-balances',
    addresses
  ],

  defiIntegrationTokensBase: ['defi-integration-tokens'],
  defiIntegrationTokensByAddress: ({ address }: Record<'address', string>) => ['defi-integration-tokens', address],
  defiIntegrationPools: ['defi-integration-pools'],
  defiIntegrationLending: ({ chain, denom }: Record<'chain' | 'denom', string>) => [
    'defi-integration-lending',
    chain,
    denom
  ],

  withdrawStTokenToDexStakeTransactionBase: ['withdraw-st-token-to-dex-stake-transaction'],
  withdrawStTokenToDexStakeTransactionByHash: ({ hash }: Record<'hash', string>) => [
    'withdraw-st-token-to-dex-stake-transaction',
    hash
  ],

  osmosisPoolsQuery: ['osmosis-pools'],
  stakedIbcxApyQuery: ['staked-ibcx-apy'],
  ibcxTokensSwapRoutesQuery: ['ibcx-tokens-swap-routes'],
  stakingStatsIbcxMarketPrice: ['staking-stats-ibcx-market-price'],
  stakeIbcxModalOsmosisPoolApr: ['stake-ibcx-modal-osmosis-pool-apr'],

  dustSweeperAllTokenValuesInUsd: ['dust-sweeper-all-token-values-in-usd'],

  tiaAirdropLeaderboards: ['tia-airdrop-leaderboards'],
  tiaAirdropLeaderboardEntry: ['tia-airdrop-leaderboard-entry'],
  tiaAirdropLeaderboardEntryByAddress: ({ address }: Record<string, string>) => [
    'tia-airdrop-leaderboard-entry',
    address
  ],

  dymAirdropLeaderboards: ['dym-airdrop-leaderboards'],
  dymAirdropLeaderboardEntry: ['dym-airdrop-leaderboard-entry'],
  dymAirdropLeaderboardEntryByAddress: ({ address }: Record<string, string>) => [
    'dym-airdrop-leaderboard-entry',
    address
  ],

  dydxAirdropBase: ['dydx-airdrop'],
  dydxAirdropsByAddress: ({ address }: Record<string, string>) => ['dydx-airdrop', address],

  tiaAirdropFulfillmentStatusBase: ['tia-airdrop-fulfillment-status'],
  tiaAirdropFulfillmentStatusByAddress: ({ address }: Record<string, string>) => [
    'tia-airdrop-fulfillment-status',
    address
  ],

  dymAirdropFulfillmentStatusBase: ['dym-airdrop-fulfillment-status'],
  dymAirdropFulfillmentStatusByAddress: ({ address }: Record<string, string>) => [
    'dym-airdrop-fulfillment-status',
    address
  ],

  chainStatus: ['chain-status']
} as const

export { queryKeys }
