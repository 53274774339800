import { useState } from 'react'
import { useInterval } from './useInterval'

// @TODO: accept interval, maybe?
const useNow = () => {
  const [now, setNow] = useState(() => new Date())

  useInterval(() => {
    setNow(new Date())
  }, 60_000)

  return { now }
}

export { useNow }
