import { useMediumScreen } from '@/hooks'
import { Box, Center, Text } from '@mantine/core'
import { useMemo } from 'react'
import { useAnnouncementFlags } from './queries'
import { useSelectedWallet } from '@/contexts/wallet'

const GlobalAnnouncement = () => {
  const { data } = useAnnouncementFlags()

  const selectedAccount = useSelectedWallet()

  const message = useMemo(() => {
    if (data?.custom) {
      return data.customMessage
    }

    if (data?.maintenance && data?.upgrade) {
      return 'Stride is currently undergoing maintenance and a scheduled upgrade, performance degradation may occur.'
    }

    if (data?.upgrade) {
      return 'Stride is currently undergoing a scheduled upgrade, performance degradation may occur.'
    }

    if (data?.maintenance) {
      return 'Stride is currently undergoing maintenance, and certain functionality may be affected. Everything should be normal soon!'
    }

    return ''
  }, [data, selectedAccount])

  const { isMediumScreen } = useMediumScreen()

  if (!message) {
    return null
  }

  return (
    <Box sx={(t) => ({ background: t.colors.yellow[1], padding: t.other.globalAnnouncement.padding })}>
      <Center>
        <Text inline={isMediumScreen} sx={(t) => ({ color: t.colors.gray[7] })}>
          {message}
        </Text>
      </Center>
    </Box>
  )
}

export { GlobalAnnouncement }
