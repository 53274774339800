import { DeliverTxResponse, BroadcastTxError } from '@cosmjs/stargate'
import { rollbar } from '@/rollbar'
import { z } from 'zod'

// To add a new error, add it to this list. Make sure the key matches the unique error code.
// then add a condition inside assertIsDeliverTxSuccess. Make sure error has a descriptive text.
const STANDARD_ERRORS = {
  TX_PARSE_ERR: 2,
  INVALID_SEQUENCE: 3,
  INSUFFICIENT_FUNDS: 5,
  UNKNOWN_REQUEST: 6,
  INVALID_ADDRESS: 7,
  INVALID_PUBKEY: 8,
  UNKNOWN_ADDRESS: 9,
  INVALID_COINS: 10,
  OUT_OF_GAS: 11,
  MEMO_TOO_LARGE: 12,
  INSUFFICIENT_FEE: 13,
  TX_TOO_LARGE: 21,
  INVALID_GAS_ADJUSTMENT: 25,
  INVALID_HEIGHT: 26,
  INVALID_VERSION: 27,
  INVALID_CHAIN_ID: 28,
  INVALID_TYPE: 29,
  TIMEOUT_HEIGHT: 30,
  INCORRECT_ACCOUNT_SEQUENCE: 32,
  INVALID_GAS_LIMIT: 41,

  // We need to make sure these have priority handling because
  // it uses the same code as standard cosmos errors.
  RATE_LIMIT_QUOTA_EXCEEDED: 4,
  RATE_LIMIT_CLIENT_STATE: 5,
  RATE_LIMIT_CHANNEL_NOT_FOUND: 6,
  RATE_LIMIT_DENOM_IS_BLACKLISTED: 7,

  // @TODO: Handle this redelegation error: Error: Query failed with (6): rpc error: code = Unknown desc = failed to execute message; message index: 0:
  // redelegation to this validator already in progress; first redelegation to this validator must complete before next redelegation [cosmos/cosmos-sdk@v0.47.10/baseapp/baseapp.go:808]
  // With gas wanted: '18446744073709551615' and gas used: '48945' : unknown request
  LSM_INSUFFICIENT_VALIDATOR_BOND_SHARES: 107,
  LSM_TOKENIZE_EXCEEDS_GLOBAL_CAPACITY: 111,
  LSM_TOKENIZE_EXCEEDS_VALIDATOR_CAPACITY: 112,
  LSM_TOKENIZE_SHARES_DISABLED_FOR_ACCOUNT: 113,
  LSM_TOKENIZE_DISABLED_REDELEGATION_IN_PROGRESS: 120,
  // ASCII text "Stride" converted to Hex
  // Just a random unique number to identify unregistered frontend errors
  FRONTEND_UNRECOGNIZED_ERROR: 537472696465
} as const

const LSM_ERROR_KEYS: Array<keyof typeof STANDARD_ERRORS> = [
  'LSM_INSUFFICIENT_VALIDATOR_BOND_SHARES',
  'LSM_TOKENIZE_EXCEEDS_GLOBAL_CAPACITY',
  'LSM_TOKENIZE_EXCEEDS_VALIDATOR_CAPACITY',
  'LSM_TOKENIZE_SHARES_DISABLED_FOR_ACCOUNT',
  'LSM_TOKENIZE_DISABLED_REDELEGATION_IN_PROGRESS'
]

// Wraps Stargate's `assertIsDeliverTxSuccess` and logs any transaction errors to Rollbar
// Also more importantly provides a descriptive text for standard broadcast errors.
// @see: https://github.com/cosmos/cosmos-sdk/blob/main/types/errors/errors.go
const assertIsDeliverTxSuccess = (result: DeliverTxResponse) => {
  if (!result.code) {
    return
  }

  assertTxSuccess(result)
}

// @TODO: Move this into its own file
// Reuse for `assertIsDeliverTxSuccess` and `broadcastTx` wrapper
// Logs any transaction errors to Rollbar, also more importantly provides a
// descriptive text for standard broadcast errors.
// @see: https://github.com/cosmos/cosmos-sdk/blob/main/types/errors/errors.go
const assertTxSuccess = (result: unknown) => {
  const payload = errorSchema.safeParse(result)

  // Highly likely it means it's not a transaction error (neither BroadcastTxError nor DeliverTxResponse)
  if (!payload.success) {
    return
  }

  // @TODO: Add a whitelist so we don't log every transaction error known to mankind
  rollbar.error(payload.data)

  if (process.env.NODE_ENV === 'development') {
    // Explicitly log the payload in development so we can see what's going on.
    console.error('Failing transaction payload:', payload.data)
  }

  // Normally, we would use codespace to know the context of the error code. Unfortunately,
  // DeliverTxResponse does not have it. Furthermore, there's nothing in the transaction
  // events we can use to determine if this is a rate limiting error.
  const log = payload.data instanceof BroadcastTxError ? payload.data.log : payload.data.rawLog

  // Simulate also and more oftenly fails because of ratelimiting. This makes testing this case hard.
  // To test this, modify the "simulate" function to return a big-enough hard-coded value so it does not fail.
  // At the same time, modify `useRateLimitQuery` to return a "capacity" bigger (in microdenom) than what it
  // currently is.
  if (log?.includes('Outflow exceeds quota') && payload.data.code === STANDARD_ERRORS.RATE_LIMIT_QUOTA_EXCEEDED) {
    throw new StandardTransactionError(
      'Unable to move your stTokens due to rate limiting',
      'RATE_LIMIT_QUOTA_EXCEEDED',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.TX_PARSE_ERR) {
    throw new StandardTransactionError(
      'Transaction failed because it could not be parsed. Please try again.',
      'TX_PARSE_ERR',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_SEQUENCE) {
    throw new StandardTransactionError(
      'Transaction failed because the sequence number is incorrect for the signature. Please try again.',
      'INVALID_SEQUENCE',
      payload.data
    )
  }

  // This happens when users does not have enough tokens to fund the transaction or the transaction's gas,
  // not to be confused with code 11 (out_of_gas)
  if (payload.data.code === STANDARD_ERRORS.INSUFFICIENT_FUNDS) {
    throw new StandardTransactionError('Insufficient funds for gas.', 'INSUFFICIENT_FUNDS', payload.data)
  }

  if (payload.data.code === STANDARD_ERRORS.UNKNOWN_REQUEST) {
    throw new StandardTransactionError(
      'Transaction failed because the request is unknown. Please try again.',
      'UNKNOWN_REQUEST',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_ADDRESS) {
    throw new StandardTransactionError(
      'Transaction failed due to an invalid address. Please try again.',
      'INVALID_ADDRESS',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_PUBKEY) {
    throw new StandardTransactionError(
      'Transaction failed due to an invalid pubkey. Please try again.',
      'INVALID_PUBKEY',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.UNKNOWN_ADDRESS) {
    throw new StandardTransactionError(
      'Transaction failed due to an unknown address. Please try again.',
      'UNKNOWN_ADDRESS',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_COINS) {
    throw new StandardTransactionError(
      'Transaction failed due to invalid coins. Please try again.',
      'INVALID_COINS',
      payload.data
    )
  }

  // This happens when the gas limit set is likely too low and the transaction ends up running out of gas halfway through.
  // This is not related to user not having enough tokens for gas (code 5). To fix this, try increasing the gas limit multiplier
  // set on the `calculateFee` function in tx.ts (in the same directory)
  if (payload.data.code === STANDARD_ERRORS.OUT_OF_GAS) {
    throw new StandardTransactionError('Transaction exceeded gas limit.', 'OUT_OF_GAS', payload.data)
  }

  if (payload.data.code === STANDARD_ERRORS.MEMO_TOO_LARGE) {
    throw new StandardTransactionError(
      'Transaction failed due memo being too large. Please try again.',
      'MEMO_TOO_LARGE',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INSUFFICIENT_FEE) {
    throw new StandardTransactionError(
      'Transaction failed due to fee being set too low. Please try again.',
      'INSUFFICIENT_FEE',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.TX_TOO_LARGE) {
    throw new StandardTransactionError(
      'Transaction failed for being too large. Please try again.',
      'TX_TOO_LARGE',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_GAS_ADJUSTMENT) {
    throw new StandardTransactionError(
      'Transaction failed due to invalid gas adjustment. Please try again.',
      'INVALID_GAS_ADJUSTMENT',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_HEIGHT) {
    throw new StandardTransactionError(
      'Transaction failed due to invalid height. Please try again.',
      'INVALID_HEIGHT',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_VERSION) {
    throw new StandardTransactionError(
      'Transaction failed due to invalid version. Please try again.',
      'INVALID_VERSION',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_CHAIN_ID) {
    throw new StandardTransactionError(
      'Transaction failed due to invalid chain-id. Please try again.',
      'INVALID_CHAIN_ID',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_TYPE) {
    throw new StandardTransactionError(
      'Transaction failed due to invalid type. Please try again.',
      'INVALID_TYPE',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.TIMEOUT_HEIGHT) {
    throw new StandardTransactionError(
      'Transaction failed due to set timeout height. Please try again.',
      'TIMEOUT_HEIGHT',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INCORRECT_ACCOUNT_SEQUENCE) {
    throw new StandardTransactionError(
      'Transaction failed due to incorrect account sequence. Please try again.',
      'INCORRECT_ACCOUNT_SEQUENCE',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.INVALID_GAS_LIMIT) {
    throw new StandardTransactionError(
      'Transaction failed due to invalid gas limit. Please try again.',
      'INVALID_GAS_LIMIT',
      payload.data
    )
  }

  // @see https://github.com/cosmos/cosmos-sdk/blob/0af2f4da004cbea6414a8bad56e8bdcd45badf1e/x/staking/types/errors.go#L60
  if (payload.data.code === STANDARD_ERRORS.LSM_INSUFFICIENT_VALIDATOR_BOND_SHARES) {
    // In case you're interested in testing this, you can either:
    // A:
    // - Set validator cap in Dockernet to 0 (Sam knows more about this); and
    // - Modify `useLsmValidatorsQuery#getValidatorRemainingBondShares` so it returns any positive, non-zero value
    // B:
    // - Try to tokenize more than the remaining bond shares of the validator
    throw new StandardTransactionError(
      'Transaction failed due to insufficient validator bond shares. Please try again.',
      'LSM_INSUFFICIENT_VALIDATOR_BOND_SHARES',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.LSM_TOKENIZE_EXCEEDS_GLOBAL_CAPACITY) {
    throw new StandardTransactionError(
      'Transaction failed due to tokenize exceeding global capacity. Please try again.',
      'LSM_TOKENIZE_EXCEEDS_GLOBAL_CAPACITY',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.LSM_TOKENIZE_EXCEEDS_VALIDATOR_CAPACITY) {
    throw new StandardTransactionError(
      'Transaction failed due to tokenize exceeding validator capacity. Please try again.',
      'LSM_TOKENIZE_EXCEEDS_VALIDATOR_CAPACITY',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.LSM_TOKENIZE_SHARES_DISABLED_FOR_ACCOUNT) {
    throw new StandardTransactionError(
      'Transaction failed due to tokenize shares being disabled for account. Please try again.',
      'LSM_TOKENIZE_SHARES_DISABLED_FOR_ACCOUNT',
      payload.data
    )
  }

  if (payload.data.code === STANDARD_ERRORS.LSM_TOKENIZE_DISABLED_REDELEGATION_IN_PROGRESS) {
    throw new StandardTransactionError(
      'Tokenization is disabled due to an on-going redelegation. Please try again when the redelegation completes.',
      'LSM_TOKENIZE_DISABLED_REDELEGATION_IN_PROGRESS',
      payload.data
    )
  }

  // If we get here, it means we're dealing with a transaction error that we haven't registered yet,
  // but is highly likely a transaction error given its data shape
  throw new StandardTransactionError(
    'This transaction could not be completed due to an unrecognized error.',
    'FRONTEND_UNRECOGNIZED_ERROR',
    payload.data
  )
}

// From my understanding, transactions can fail in two ways:
// Transactions fails immediately; no block is committed
// Transactions fail deep in the process; block is committed
// BroadcastTxError is the former; this is the data we get back from the chain
const broadcastTxErrorSchema = z.object({
  code: z.number(),
  codespace: z.string(),
  log: z.string().optional()
})

const attributeSchema = z.object({ key: z.string(), value: z.string() })

const eventSchema = z.object({ type: z.string(), attributes: z.array(attributeSchema) })

const deliverTxSchema = z.object({
  code: z.number(),
  // Later versions of cosmos seems to use bigints. Until we knew, our error
  // handling below stopped working because schema was technically incorrect.
  gasUsed: z.number().or(z.bigint()),
  // Later versions of cosmos seems to use bigints. Until we knew, our error
  // handling below stopped working because schema was technically incorrect.
  gasWanted: z.number().or(z.bigint()),
  height: z.number(),
  rawLog: z.string().optional(),
  transactionHash: z.string(),
  txIndex: z.number(),
  events: z.array(eventSchema)
})

const errorSchema = deliverTxSchema.or(broadcastTxErrorSchema)

// Allow our UI to identify if a transaction we're running into is
// - A recognized transaction error from Cosmos or Stride
// - We have a proper error message designated for it
class StandardTransactionError extends Error {
  // A short, distinct description of the error set on the Frontend-side
  // to allow type-safety for all the standard errors we register.
  // You can do error.description === 'TX_PARSE_ERR' to check for a specific error
  // without having to make any imports.
  description: keyof typeof STANDARD_ERRORS

  // Transaction error code provided by the chain
  code: number

  // Raw json error so we can show it to the user
  raw: z.infer<typeof errorSchema>

  constructor(message: string, description: keyof typeof STANDARD_ERRORS, raw: z.infer<typeof errorSchema>) {
    super(message)
    this.description = description
    this.code = STANDARD_ERRORS[description]
    this.raw = raw
  }
}

// @TODO: We need to have a proper list of errors that may occur, these are random values for now
const SIMULATION_ERRORS = {
  // ASCII text "RATE" converted to Hex
  // Just a random unique number to identify rate limiting for now
  RATE_LIMITING: 52415445,
  // Random number to represent
  LSM_INSUFFICIENT_VALIDATOR_BOND_SHARES: 55415500,
  // Random number to represent
  LSM_REDELEGATION_IN_PROGRESS: 55415900,
  // ASCII text "Stride" converted to Hex
  // Just a random unique number to identify unregistered frontend errors
  FRONTEND_UNRECOGNIZED_ERROR: 537472696465
} as const

// @TODO: Move this into its own file
const assertSimulationSuccess = (e: unknown) => {
  const payload = parseAbciError(e)

  // Highly likely it means it's not a BroadcastTxError
  if (!payload.success) {
    return
  }

  if (process.env.NODE_ENV === 'development') {
    // Explicitly log the payload in development so we can see what's going on.
    console.error('Failing transaction payload:', payload.data)
  }

  // @TODO: Add a whitelist so we don't log every transaction error known to mankind
  rollbar.error(payload.data)

  // "rpc error: code = Unknown desc = failed to execute message; message index: 0: Outflow exceeds quota - Net Outflow: 44000000000,
  // Channel Value: 44317030259, Threshold: 10%: quota exceeded [Stride-Labs/stride/v16/x/ratelimit/types/flow.go:40] With gas wanted:
  // '18446744073709551615' and gas used: '90468' : unknown request"
  if (
    payload.data.code === 6 &&
    payload.data.log &&
    payload.data.log.includes('Outflow exceeds quota') &&
    payload.data.log.includes('x/ratelimit')
  ) {
    throw new StandardSimulationError(
      'Unable to move your stTokens due to rate limiting',
      'RATE_LIMITING',
      payload.data
    )
  }

  // rpc error: code = Unknown desc = failed to execute message; message index: 0: insufficient validator bond shares
  // [cosmos/cosmos-sdk@v0.47.11/baseapp/baseapp.go:808] With gas wanted: '18446744073709551615' and gas used: '52676' : unknown request
  if (payload.data.code === 6 && payload.data.log && payload.data.log.includes('insufficient validator bond shares')) {
    throw new StandardSimulationError(
      'Transaction failed due to insufficient validator bond shares. Please try again.',
      'LSM_INSUFFICIENT_VALIDATOR_BOND_SHARES',
      payload.data
    )
  }

  if (
    payload.data.code === 6 &&
    payload.data.log &&
    payload.data.log.includes(
      'delegator is not allowed to tokenize shares from validator with a redelegation in progress'
    )
  ) {
    throw new StandardSimulationError(
      'Tokenization is disabled due to an on-going redelegation. Please try again when the redelegation completes.',
      'LSM_REDELEGATION_IN_PROGRESS',
      payload.data
    )
  }

  throw new StandardSimulationError(
    'Unable to calculate gas fee due to an unknown error.',
    'FRONTEND_UNRECOGNIZED_ERROR',
    payload.data
  )
}

interface AbciError {
  code: number
  log: string
}

type AbciParseReturnType = { success: true; data: AbciError } | { success: false }

const QUERY_ABCI_FAILURE_PREFIX = /Query failed with \(\d\)\:/

// Unfortunately, we can't use a bzod parser here.
// When simulation fails, it seems to be caused by a query to abci.
// And the problem is the query to abci wraps the entire response shape
// into a string: `throw new Error('Query failed with (${code}): ${log}`).
// This means we lose the raw information, and have to parse.
const parseAbciError = (error: unknown): AbciParseReturnType => {
  if (!(error instanceof Error)) {
    return { success: false }
  }

  const prefixes = error.message.match(QUERY_ABCI_FAILURE_PREFIX)

  // First, we'll check if the prefix 'Query failed with (6):' exists
  if (!prefixes?.length) {
    return { success: false }
  }

  const codes = prefixes[0].match(/\d/)

  if (!codes?.length) {
    return { success: false }
  }

  const data = {
    // Get code from 'Query failed with (6):'
    code: Number(codes[0]),
    // Anything after 'Query failed with (6):' is the log. We'll trim the message from the left.
    log: error.message.replace(QUERY_ABCI_FAILURE_PREFIX, '').trimStart()
  }

  return { success: true, data }
}

// Allow our UI to identify if a transaction we're running into is
// - A recognized transaction error from Cosmos or Stride
// - We have a proper error message designated for it
// Simulation shares standard shape (code, codespace, log) as BroadcastTxError
class StandardSimulationError extends Error {
  // A short, distinct description of the error set on the Frontend-side
  // to allow type-safety for all the standard errors we register.
  // You can do error.description === 'TX_PARSE_ERR' to check for a specific error
  // without having to make any imports.
  description: keyof typeof SIMULATION_ERRORS

  // Transaction error code provided by the chain
  code: number

  // Raw json error so we can show it to the user
  raw: AbciError

  constructor(message: string, description: keyof typeof SIMULATION_ERRORS, raw: AbciError) {
    super(message)
    this.description = description
    this.code = SIMULATION_ERRORS[description]
    this.raw = raw
  }
}

export {
  assertIsDeliverTxSuccess,
  assertTxSuccess,
  assertSimulationSuccess,
  StandardTransactionError,
  StandardSimulationError,
  LSM_ERROR_KEYS
}
