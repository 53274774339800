import { config } from '@/config'
import { ChainProvider } from '@/contexts/chain'
import { ColorSchemeProvider } from '@/contexts/color-scheme'
import { WalletSelectProvider } from '@/contexts/connect-wallet'
import MantineProvider from '@/contexts/mantine'
import NotificationsProvider from '@/contexts/notifications'
import QueryClientProvider from '@/contexts/query'
import QueryParamsProvider from '@/contexts/query'
import { RollbarProvider } from '@/contexts/rollbar'
import { TopBannerProvider } from '@/contexts/top-banner'
import { WalletProvider } from '@/contexts/wallet'
import { GlobalLayout, GlobalLayoutProvider } from '@/page-components/GlobalLayout'
import { ReferralProvider } from '@/page-components/Referral'
import '@/page-components/shared/TiaAirdropSwapBannerModal/style.css'

import { Global } from '@mantine/core'
import type { AppProps } from 'next/app'
import Head from 'next/head'

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>{`${config.app.title} | ${config.app.tagline}`}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta content="A protocol for multichain liquid staking in the Cosmos ecosystem" name="description" />
        <meta content="Stride | Multichain Liquid Staking" property="og:title" />
        <meta content="A protocol for multichain liquid staking in the Cosmos ecosystem" property="og:description" />
        <meta content="/stride-og.png?v=3" property="og:image" />
        <meta content="Stride | Multichain Liquid Staking" property="twitter:title" />
        <meta
          content="A protocol for multichain liquid staking in the Cosmos ecosystem"
          property="twitter:description"
        />
        {/* `twitter:image` only works with absolute urls */}
        <meta content="https://app.stride.zone/stride-og.png?v=3" name="twitter:image" />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <link href="/favicon.png?v=2" rel="shortcut icon" type="image/x-icon" />
        <link href="/webclip.png?v=2" rel="apple-touch-icon" />
      </Head>

      <ColorSchemeProvider>
        <MantineProvider>
          {/* We are placing this here so we can style our fallback UI when our app crashes. */}
          {/* For awareness, this strongly assumes that both providers above won't crash. */}
          <RollbarProvider>
            <QueryClientProvider>
              <QueryParamsProvider>
                <NotificationsProvider>
                  <TopBannerProvider>
                    <ChainProvider>
                      <WalletSelectProvider>
                        <WalletProvider>
                          <GlobalLayoutProvider>
                            <GlobalLayout>
                              <ReferralProvider>
                                <Component {...pageProps} />
                              </ReferralProvider>
                            </GlobalLayout>
                          </GlobalLayoutProvider>
                        </WalletProvider>
                      </WalletSelectProvider>
                    </ChainProvider>
                  </TopBannerProvider>
                </NotificationsProvider>
              </QueryParamsProvider>
            </QueryClientProvider>
          </RollbarProvider>

          <Global
            styles={[
              {
                '*, *::before, *::after': {
                  boxSizing: 'border-box'
                },

                body: {
                  background: '#FFEDF9'
                }
              },
              {
                '@font-face': {
                  fontFamily: 'PP Telegraf',
                  fontWeight: 400,
                  src: 'url("/fonts/PPTelegraf-Regular.ttf")'
                }
              },
              {
                '@font-face': {
                  fontFamily: 'PP Telegraf',
                  fontWeight: 600,
                  src: 'url("/fonts/PPTelegraf-Semibold.ttf")'
                }
              },
              {
                '@font-face': {
                  fontFamily: 'PP Telegraf',
                  fontWeight: 800,
                  src: 'url("/fonts/PPTelegraf-Ultrabold.otf")'
                }
              }
            ]}
          />
        </MantineProvider>
      </ColorSchemeProvider>
    </>
  )
}

export default App
