import React from 'react'
import { Text, TextProps } from '@mantine/core'
import { InlineLoader } from '@/components'

interface TextLoaderOwnProps {
  loading: boolean
}

type TextLoaderProps<T> = TextProps<T> & TextLoaderOwnProps

function TextLoader<T = 'div'>({ loading, ...props }: TextLoaderProps<T>): React.ReactElement {
  return loading ? <InlineLoader /> : <Text {...props} />
}

export { TextLoader }
