import { Registry } from '@cosmjs/proto-signing'
import { AminoTypes, createDefaultAminoConverters, defaultRegistryTypes } from '@cosmjs/stargate'
import { uniqWith } from 'lodash'
import { cosmosProtoRegistry, strideProtoRegistry, cosmosAminoConverters, strideAminoConverters } from 'stridejs'

// Ideally, we'd use the actual factory functions from the stride/cosmos sdk,
// but due to annoying type errors, we'll keep a simple constant file to reference
// from for now.
//
// @TODO: Let's use functions like `stride.staketia.MessageComposer.withTypeUrl.liquidStake` moving forward
// We might not be able to remove this file completely as we need to reference the typeUrl for the type-guards
const TX_MSG = {
  MsgTransfer: '/ibc.applications.transfer.v1.MsgTransfer',
  MsgTokenizeShares: '/cosmos.staking.v1beta1.MsgTokenizeShares',
  MsgBeginRedelegate: '/cosmos.staking.v1beta1.MsgBeginRedelegate',
  MsgRedeemTokensForShares: '/cosmos.staking.v1beta1.MsgRedeemTokensForShares',
  MsgLiquidStake: '/stride.stakeibc.MsgLiquidStake',
  StakeTiaMsgLiquidStake: '/stride.staketia.MsgLiquidStake',
  StakeDymMsgLiquidStake: '/stride.stakedym.MsgLiquidStake',
  MsgLSMLiquidStake: '/stride.stakeibc.MsgLSMLiquidStake',
  MsgRedeemStake: '/stride.stakeibc.MsgRedeemStake',
  StakeTiaMsgRedeemStake: '/stride.staketia.MsgRedeemStake',
  StakeDymMsgRedeemStake: '/stride.stakedym.MsgRedeemStake',
  MsgClaimUndelegatedTokens: '/stride.stakeibc.MsgClaimUndelegatedTokens'
}

const registryTypes = new Registry(
  uniqWith([...strideProtoRegistry, ...cosmosProtoRegistry, ...defaultRegistryTypes], ([a], [b]) => a === b)
)

const aminoTypes = new AminoTypes({
  ...createDefaultAminoConverters(),
  ...cosmosAminoConverters,
  ...strideAminoConverters
})

export { TX_MSG, registryTypes, aminoTypes }
