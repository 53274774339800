import { createContext, useContext, useMemo } from 'react'

import { CHAIN_INFO_LIST } from '@/config'
import { ChainProvider as CosmosKitProvider } from '@cosmos-kit/react-lite'
import { assets, chains } from 'chain-registry'

// We import Leap in separate packages because they added their MetaMask Snap to @cosmos-kit/leap
import { wallets as CosmostationWallet } from '@cosmos-kit/cosmostation'
import { wallets as KeplrWallet } from '@cosmos-kit/keplr'
// import { wallets as MetaMaskWallet } from '@cosmos-kit/cosmos-extension-metamask'
import { wallets as LeapExtensionWallet } from '@cosmos-kit/leap-extension'
import { wallets as LeapMobileWallet } from '@cosmos-kit/leap-mobile'
import { wallets as OmniWallet } from '@cosmos-kit/omni'
import { wallets as XdefiWallet } from '@cosmos-kit/xdefi'

import { env, fatal } from '@/utils'
import { accountParser } from '@/wallet-utils/parser'
import { SignerOptions } from '@cosmos-kit/core'
import { registryTypes, aminoTypes } from '@/wallet-utils'

export interface ChainContext {}

export const Chain = createContext<ChainContext>({})

const LeapWallet = [...LeapExtensionWallet, ...LeapMobileWallet]

const wallets = [...KeplrWallet, ...XdefiWallet, ...LeapWallet, ...OmniWallet, ...CosmostationWallet]

export function ChainProvider({ children }: { children: React.ReactNode }) {
  const signerOptions: SignerOptions = {
    preferredSignType: () => 'amino',
    stargate: () => {
      return { accountParser }
    },
    signingStargate: () => {
      return {
        registry: registryTypes,
        aminoTypes,
        accountParser
      }
    }
  }

  const chainList = useMemo(
    () =>
      Object.entries(CHAIN_INFO_LIST).map(([_, chainInfo]) => {
        const chain = chains.find((chain) => {
          // chainId would not work for different environments (Dockernet, Testnet).
          // Bech32 seems like a safe bet.
          return chain.bech32_prefix === chainInfo.bech32Config.bech32PrefixAccAddr
        })

        if (chain == null) {
          throw fatal(`Unable to find chain id ${chainInfo.chainId} from chain registry.`)
        }

        return {
          ...chain,
          chain_id: chainInfo.chainId,
          pretty_name: chainInfo.chainName,
          apis: {
            rpc: [
              {
                address: chainInfo.rpc,
                provider: 'Stride Edge Server'
              }
            ],
            rest: [
              {
                address: chainInfo.rest,
                provider: 'Stride Edge Server'
              }
            ]
          }
        }
      }),
    [CHAIN_INFO_LIST, chains]
  )

  const endpoints = useMemo(() => {
    return Object.fromEntries(
      chainList.map((chain) => {
        return [
          chain.chain_name,
          {
            // @TODO: Report to cosmology team.
            // This is important otherwise cosmos-kit addChain will break, ergo breaking suggestChain
            // from *automatically* working (without any extra code). There seems to be a disconnected
            // between addChain and the rest of cosmos-kit. In Keplr addChain, it tries to set `suggestChain.rpc`
            // to this value strongly assuming it's a string, when type-wise it is an object or string.
            // rpc: [chain.apis.rpc[0].address],
            // rest: [chain.apis.rest[0].address],
            //
            // For now, we commented the above to disable cosmos-kit from automatically calling suggestChain (addChain)
            // because it prevents us from making it work properly with relevant chains, like ISLM. For now, we are manualliy
            // calling the `experimentalSuggestChain` from relevant wallets. We can probably fix this once we address our
            // fundamental implementation of cosmos-kit: https://github.com/Stride-Labs/interface/pull/714
            rpc: [{ url: chain.apis.rpc[0].address, headers: {} }],
            rest: [{ url: chain.apis.rest[0].address, headers: {} }],
            isLazy: true
          }
        ]
      })
    )
  }, [chainList])

  return (
    <CosmosKitProvider
      chains={chainList}
      assetLists={assets}
      wallets={wallets}
      walletModal={() => <></>}
      signerOptions={signerOptions}
      walletConnectOptions={{
        signClient: {
          projectId: 'e2b8f56d4017ea84ec1704c89861748f'
        }
      }}
      endpointOptions={{ isLazy: true, endpoints }}
      logLevel={
        process.env.NODE_ENV === 'development' && env('COSMOS_KIT_LOGS_ENABLED') === 'true' ? 'DEBUG' : 'ERROR'
      }>
      <Chain.Provider value={{}}>{children}</Chain.Provider>
    </CosmosKitProvider>
  )
}

export const useChain = (): ChainContext => useContext(Chain)
