import * as React from 'react'
import { SVGProps } from 'react'
const SvgInfoCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0Zm1 15H9V9h2v6Zm0-8H9V5h2v2Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgInfoCircleFilled
