import * as React from 'react'
import { SVGProps } from 'react'
const SvgCross = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.571 1.429 1.43 10.57m0-9.142 9.142 9.142"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgCross
