import { OfflineAminoSigner, StdFee, encodeSecp256k1Pubkey, makeSignDoc } from '@cosmjs/amino'
import { fromBase64 } from '@cosmjs/encoding'
import { Int53 } from '@cosmjs/math'
import { EncodeObject, TxBodyEncodeObject, encodePubkey, makeAuthInfoBytes } from '@cosmjs/proto-signing'
import { AminoTypes, SignerData, SigningStargateClient, createDefaultAminoConverters } from '@cosmjs/stargate'
import { SignMode } from 'cosmjs-types/cosmos/tx/signing/v1beta1/signing'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'

export async function skipSignAmino(
  client: SigningStargateClient,
  signer: OfflineAminoSigner,
  signerAddress: string,
  messages: readonly EncodeObject[],
  fee: StdFee,
  memo: string,
  { accountNumber, sequence, chainId }: SignerData
) {
  const aminoTypes = new AminoTypes(createDefaultAminoConverters())

  const accountFromSigner = (await signer.getAccounts()).find((account) => account.address === signerAddress)
  if (!accountFromSigner) {
    throw new Error('Failed to retrieve account from signer')
  }

  const pubkey = encodePubkey(encodeSecp256k1Pubkey(accountFromSigner.pubkey))

  const signMode = SignMode.SIGN_MODE_LEGACY_AMINO_JSON

  const msgs = messages.map((msg) => aminoTypes.toAmino(msg))

  for (let i = 0; i < msgs.length; i++) {
    msgs[i].value.memo = messages[i].value.memo
  }

  const signDoc = makeSignDoc(msgs, fee, chainId, memo, accountNumber, sequence)

  const { signature, signed } = await signer.signAmino(signerAddress, signDoc)

  const signedTxBody = {
    messages: signed.msgs.map((msg) => aminoTypes.fromAmino(msg)),
    memo: signed.memo
  }

  console.log(signedTxBody)

  for (let i = 0; i < signedTxBody.messages.length; i++) {
    signedTxBody.messages[i].value.memo = messages[i].value.memo
  }

  const signedTxBodyEncodeObject: TxBodyEncodeObject = {
    typeUrl: '/cosmos.tx.v1beta1.TxBody',
    value: signedTxBody
  }

  const signedTxBodyBytes = client.registry.encode(signedTxBodyEncodeObject)

  const signedGasLimit = Int53.fromString(signed.fee.gas).toNumber()
  const signedSequence = Int53.fromString(signed.sequence).toNumber()

  const signedAuthInfoBytes = makeAuthInfoBytes(
    [{ pubkey, sequence: signedSequence }],
    signed.fee.amount,
    signedGasLimit,
    signed.fee.granter,
    signed.fee.payer,
    signMode
  )

  return TxRaw.fromPartial({
    bodyBytes: signedTxBodyBytes,
    authInfoBytes: signedAuthInfoBytes,
    signatures: [fromBase64(signature.signature)]
  })
}
