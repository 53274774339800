import { NotificationsProvider as OriginalNotificationsProvider } from '@mantine/notifications'
import { useMantineTheme } from '@mantine/core'

import { showNotification } from '@mantine/notifications'
import { Icon } from '@/components'

export const notify = {
  error: (message: string) => {
    // TODO: Add icon
    showNotification({
      title: 'Oops!',
      autoClose: false,
      message,
      color: 'red',
      icon: <Icon name="cross" fill="white" size="xs" sx={{ lineHeight: 0 }} />
    })
  },

  success: (message: string) => {
    // TODO: Add icon
    showNotification({
      title: 'Nice!',
      autoClose: 10000,
      message,
      color: 'cyan',
      icon: <Icon name="check" fill="white" size="xs" sx={{ lineHeight: 0 }} />
    })
  },

  progress: (title: string, message: string) => {
    showNotification({
      title,
      autoClose: 5000,
      message,
      color: 'cyan',
      loading: true
    })
  }
}

const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useMantineTheme()

  return (
    <OriginalNotificationsProvider position="bottom-right" zIndex={theme.other.zIndex.notifications} limit={5}>
      {children}
    </OriginalNotificationsProvider>
  )
}

export default NotificationsProvider
