import { pickBy } from 'lodash'

// Unfortunately,
// Keep this file in-sync with .env.example
const ENV = {
  IBC_TIMEOUT_TESTING: process.env.NEXT_PUBLIC_IBC_TIMEOUT_TESTING,
  IBC_RETURN_LATER_MS: process.env.NEXT_PUBLIC_IBC_RETURN_LATER_MS,
  GAIA_RPC: process.env.NEXT_PUBLIC_GAIA_RPC,
  GAIA_REST: process.env.NEXT_PUBLIC_GAIA_REST,
  GAIA_CHAIN_ID: process.env.NEXT_PUBLIC_GAIA_CHAIN_ID,
  GAIA_CHAIN_NAME: process.env.NEXT_PUBLIC_GAIA_CHAIN_NAME,
  GAIA_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_GAIA_DEPOSIT_CHANNEL,
  GAIA_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_GAIA_WITHDRAW_CHANNEL,
  STARS_RPC: process.env.NEXT_PUBLIC_STARS_RPC,
  STARS_REST: process.env.NEXT_PUBLIC_STARS_REST,
  STARS_CHAIN_ID: process.env.NEXT_PUBLIC_STARS_CHAIN_ID,
  STARS_CHAIN_NAME: process.env.NEXT_PUBLIC_STARS_CHAIN_NAME,
  STARS_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_STARS_DEPOSIT_CHANNEL,
  STARS_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_STARS_WITHDRAW_CHANNEL,
  OSMO_RPC: process.env.NEXT_PUBLIC_OSMO_RPC,
  OSMO_REST: process.env.NEXT_PUBLIC_OSMO_REST,
  OSMO_CHAIN_ID: process.env.NEXT_PUBLIC_OSMO_CHAIN_ID,
  OSMO_CHAIN_NAME: process.env.NEXT_PUBLIC_OSMO_CHAIN_NAME,
  OSMO_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_OSMO_DEPOSIT_CHANNEL,
  OSMO_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_OSMO_WITHDRAW_CHANNEL,
  STRIDE_RPC: process.env.NEXT_PUBLIC_STRIDE_RPC,
  STRIDE_REST: process.env.NEXT_PUBLIC_STRIDE_REST,
  STRIDE_CHAIN_ID: process.env.NEXT_PUBLIC_STRIDE_CHAIN_ID,
  STRIDE_CHAIN_NAME: process.env.NEXT_PUBLIC_STRIDE_CHAIN_NAME,
  STRIDE_FAUCET_API_URL: process.env.NEXT_PUBLIC_STRIDE_FAUCET_API_URL,
  JUNO_RPC: process.env.NEXT_PUBLIC_JUNO_RPC,
  JUNO_REST: process.env.NEXT_PUBLIC_JUNO_REST,
  JUNO_CHAIN_ID: process.env.NEXT_PUBLIC_JUNO_CHAIN_ID,
  JUNO_CHAIN_NAME: process.env.NEXT_PUBLIC_JUNO_CHAIN_NAME,
  JUNO_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_JUNO_DEPOSIT_CHANNEL,
  JUNO_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_JUNO_WITHDRAW_CHANNEL,
  LUNA_RPC: process.env.NEXT_PUBLIC_LUNA_RPC,
  LUNA_REST: process.env.NEXT_PUBLIC_LUNA_REST,
  LUNA_CHAIN_ID: process.env.NEXT_PUBLIC_LUNA_CHAIN_ID,
  LUNA_CHAIN_NAME: process.env.NEXT_PUBLIC_LUNA_CHAIN_NAME,
  LUNA_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_LUNA_DEPOSIT_CHANNEL,
  LUNA_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_LUNA_WITHDRAW_CHANNEL,
  EVMOS_RPC: process.env.NEXT_PUBLIC_EVMOS_RPC,
  EVMOS_REST: process.env.NEXT_PUBLIC_EVMOS_REST,
  EVMOS_CHAIN_ID: process.env.NEXT_PUBLIC_EVMOS_CHAIN_ID,
  EVMOS_CHAIN_NAME: process.env.NEXT_PUBLIC_EVMOS_CHAIN_NAME,
  EVMOS_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_EVMOS_DEPOSIT_CHANNEL,
  EVMOS_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_EVMOS_WITHDRAW_CHANNEL,
  INJ_RPC: process.env.NEXT_PUBLIC_INJ_RPC,
  INJ_REST: process.env.NEXT_PUBLIC_INJ_REST,
  INJ_CHAIN_ID: process.env.NEXT_PUBLIC_INJ_CHAIN_ID,
  INJ_CHAIN_NAME: process.env.NEXT_PUBLIC_INJ_CHAIN_NAME,
  INJ_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_INJ_DEPOSIT_CHANNEL,
  INJ_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_INJ_WITHDRAW_CHANNEL,
  UMEE_RPC: process.env.NEXT_PUBLIC_UMEE_RPC,
  UMEE_REST: process.env.NEXT_PUBLIC_UMEE_REST,
  UMEE_CHAIN_ID: process.env.NEXT_PUBLIC_UMEE_CHAIN_ID,
  UMEE_CHAIN_NAME: process.env.NEXT_PUBLIC_UMEE_CHAIN_NAME,
  UMEE_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_UMEE_DEPOSIT_CHANNEL,
  UMEE_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_UMEE_WITHDRAW_CHANNEL,
  CRE_RPC: process.env.NEXT_PUBLIC_CRE_RPC,
  CRE_REST: process.env.NEXT_PUBLIC_CRE_REST,
  CRE_CHAIN_ID: process.env.NEXT_PUBLIC_CRE_CHAIN_ID,
  CRE_CHAIN_NAME: process.env.NEXT_PUBLIC_CRE_CHAIN_NAME,
  CRE_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_CRE_DEPOSIT_CHANNEL,
  CRE_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_CRE_WITHDRAW_CHANNEL,
  CMDX_RPC: process.env.NEXT_PUBLIC_CMDX_RPC,
  CMDX_REST: process.env.NEXT_PUBLIC_CMDX_REST,
  CMDX_CHAIN_ID: process.env.NEXT_PUBLIC_CMDX_CHAIN_ID,
  CMDX_CHAIN_NAME: process.env.NEXT_PUBLIC_CMDX_CHAIN_NAME,
  CMDX_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_CMDX_DEPOSIT_CHANNEL,
  CMDX_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_CMDX_WITHDRAW_CHANNEL,
  SOMM_RPC: process.env.NEXT_PUBLIC_SOMM_RPC,
  SOMM_REST: process.env.NEXT_PUBLIC_SOMM_REST,
  SOMM_CHAIN_ID: process.env.NEXT_PUBLIC_SOMM_CHAIN_ID,
  SOMM_CHAIN_NAME: process.env.NEXT_PUBLIC_SOMM_CHAIN_NAME,
  SOMM_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_SOMM_DEPOSIT_CHANNEL,
  SOMM_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_SOMM_WITHDRAW_CHANNEL,
  DYDX_RPC: process.env.NEXT_PUBLIC_DYDX_RPC,
  DYDX_REST: process.env.NEXT_PUBLIC_DYDX_REST,
  DYDX_CHAIN_ID: process.env.NEXT_PUBLIC_DYDX_CHAIN_ID,
  DYDX_CHAIN_NAME: process.env.NEXT_PUBLIC_DYDX_CHAIN_NAME,
  DYDX_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_DYDX_DEPOSIT_CHANNEL,
  DYDX_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_DYDX_WITHDRAW_CHANNEL,
  TIA_RPC: process.env.NEXT_PUBLIC_TIA_RPC,
  TIA_REST: process.env.NEXT_PUBLIC_TIA_REST,
  TIA_CHAIN_ID: process.env.NEXT_PUBLIC_TIA_CHAIN_ID,
  TIA_CHAIN_NAME: process.env.NEXT_PUBLIC_TIA_CHAIN_NAME,
  TIA_DEPOSIT_CHANNEL: process.env.PUBLIC_TIA_DEPOSIT_CHANNEL,
  TIA_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_TIA_WITHDRAW_CHANNEL,
  DYM_RPC: process.env.NEXT_PUBLIC_DYM_RPC,
  DYM_REST: process.env.NEXT_PUBLIC_DYM_REST,
  DYM_CHAIN_ID: process.env.NEXT_PUBLIC_DYM_CHAIN_ID,
  DYM_CHAIN_NAME: process.env.NEXT_PUBLIC_DYM_CHAIN_NAME,
  DYM_DEPOSIT_CHANNEL: process.env.PUBLIC_TIA_DDYMSIT_CHANNEL,
  DYM_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_DYM_WITHDRAW_CHANNEL,
  SAGA_RPC: process.env.NEXT_PUBLIC_SAGA_RPC,
  SAGA_REST: process.env.NEXT_PUBLIC_SAGA_REST,
  SAGA_CHAIN_ID: process.env.NEXT_PUBLIC_SAGA_CHAIN_ID,
  SAGA_CHAIN_NAME: process.env.NEXT_PUBLIC_SAGA_CHAIN_NAME,
  SAGA_DEPOSIT_CHANNEL: process.env.PUBLIC_SAGA_DEPOSIT_CHANNEL,
  SAGA_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_SAGA_WITHDRAW_CHANNEL,
  ISLM_RPC: process.env.NEXT_PUBLIC_ISLM_RPC,
  ISLM_REST: process.env.NEXT_PUBLIC_ISLM_REST,
  ISLM_CHAIN_ID: process.env.NEXT_PUBLIC_ISLM_CHAIN_ID,
  ISLM_CHAIN_NAME: process.env.NEXT_PUBLIC_ISLM_CHAIN_NAME,
  ISLM_DEPOSIT_CHANNEL: process.env.PUBLIC_ISLM_DEPOSIT_CHANNEL,
  ISLM_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_ISLM_WITHDRAW_CHANNEL,
  SCRT_RPC: process.env.NEXT_PUBLIC_SCRT_RPC,
  SCRT_REST: process.env.NEXT_PUBLIC_SCRT_REST,
  SCRT_CHAIN_ID: process.env.NEXT_PUBLIC_SCRT_CHAIN_ID,
  SCRT_CHAIN_NAME: process.env.NEXT_PUBLIC_SCRT_CHAIN_NAME,
  SCRT_DEPOSIT_CHANNEL: process.env.NEXT_PUBLIC_SCRT_DEPOSIT_CHANNEL,
  SCRT_WITHDRAW_CHANNEL: process.env.NEXT_PUBLIC_SCRT_WITHDRAW_CHANNEL,
  UPSTASH_REDIS_MAIN_URL: process.env.NEXT_PUBLIC_UPSTASH_REDIS_MAIN_URL,
  UPSTASH_REDIS_MAIN_TOKEN: process.env.NEXT_PUBLIC_UPSTASH_REDIS_MAIN_TOKEN,
  UPSTASH_REDIS_AIRDROP_URL: process.env.NEXT_PUBLIC_UPSTASH_REDIS_AIRDROP_URL,
  UPSTASH_REDIS_AIRDROP_TOKEN: process.env.NEXT_PUBLIC_UPSTASH_REDIS_AIRDROP_TOKEN,
  UPSTASH_REDIS_ANNOUNCEMENT_URL: process.env.NEXT_PUBLIC_UPSTASH_REDIS_ANNOUNCEMENT_URL,
  UPSTASH_REDIS_ANNOUNCEMENT_TOKEN: process.env.NEXT_PUBLIC_UPSTASH_REDIS_ANNOUNCEMENT_TOKEN,
  ROLLBAR_ENV: process.env.NEXT_PUBLIC_ROLLBAR_ENV,
  ROLLBAR_TOKEN: process.env.NEXT_PUBLIC_ROLLBAR_TOKEN,
  LSM_TUTORIAL_ENABLED: process.env.NEXT_PUBLIC_LSM_TUTORIAL_ENABLED,
  COSMOS_KIT_LOGS_ENABLED: process.env.NEXT_PUBLIC_COSMOS_KIT_LOGS_ENABLED,
  TIA_AIRDROP_QUERY_PLACEHOLDER_ENABLED: process.env.NEXT_PUBLIC_TIA_AIRDROP_QUERY_PLACEHOLDER_ENABLED,
  DYM_AIRDROP_QUERY_PLACEHOLDER_ENABLED: process.env.NEXT_PUBLIC_DYM_AIRDROP_QUERY_PLACEHOLDER_ENABLED,
  // We cannot reliable use NODE_ENV to display a particular UI in staging because next build will automatically set NODE_ENV=production.
  // As a workaround, we'll use STRIDE_ENV which should only be set locally and staging. In production, this should not exist.
  STRIDE_ENV: process.env.NEXT_PUBLIC_STRIDE_ENV
}

const object = pickBy(ENV, Boolean)

// Shortcut for getting next environment keys
const env = (key: keyof typeof ENV, defaultValue?: string): string => {
  return object[key] ?? defaultValue ?? ''
}

export { env }
