import React from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { Anchor, Box, Stack } from '@mantine/core'
import logo from '@/images/logo-light.svg'
import { config } from '@/config'
import { useMediumScreen } from '@/hooks'
import { SidebarLiteLink } from './SidebarLiteLink'
import { SidebarLiteExternalLink } from './SidebarLiteExternalLink'
import { FEATURE_FLAGS } from '@/feature-flags'

// Make sure that any link you add here is added to `NavigationMobileMenu`
const SidebarLite: React.FC = () => {
  const { isMediumScreen } = useMediumScreen()

  if (!isMediumScreen) {
    return null
  }

  return (
    <>
      <Box sx={{ height: '100vh', width: 214, flexShrink: 0 }} />

      <Box sx={{ position: 'fixed', top: 0, left: 0 }}>
        <Stack
          sx={(t) => ({
            width: 214,
            background: t.white,
            height: '100vh'
          })}>
          <Box pl={20} pr="md" pt={36} sx={{ width: '100%' }}>
            <Box sx={{ width: 80 }}>
              <Link href="/" passHref legacyBehavior>
                <Anchor inline>
                  <Image src={logo} alt="Stride Logo" />
                </Anchor>
              </Link>
            </Box>
          </Box>

          <Stack spacing={12} px={12} sx={{ width: '100%' }}>
            <SidebarLiteLink href="/" icon="liquidStake" label="Liquid Staking" />
            <SidebarLiteLink href="/airdrop" icon="airdrop" label="Airdrops" />
            <SidebarLiteLink href="/earn" icon="earn" label="Earn" />
            <SidebarLiteLink href="/sweeper" icon="dustSweeper" label="Dust Sweeper" />
          </Stack>

          <Stack spacing={12} mt="auto" p="xs" sx={{ width: '100%' }}>
            {FEATURE_FLAGS.STATUS_PAGE ? (
              <SidebarLiteLink href="/status" icon="status" label="Status" />
            ) : (
              <SidebarLiteExternalLink href={config.links.stake} icon="stakeStrd" label="Stake STRD" />
            )}

            <SidebarLiteExternalLink href={config.links.stats} icon="barStats" label="Ecosystem Stats" />
            <SidebarLiteExternalLink href={config.links.support} icon="questionCircle" label="Support" />
          </Stack>
        </Stack>
      </Box>
    </>
  )
}
export { SidebarLite }
