import { Icon } from '@/components'
import { useMediumScreen } from '@/hooks'
import { Box, Space, Stack, Transition, UnstyledButton } from '@mantine/core'
import React from 'react'
import { config } from '@/config'
import { NavigationMobileMenuConnectWallet } from './NavigationMobileMenuConnectWallet'
import { NavigationMobileMenuItem } from './NavigationMobileMenuItem'
import { useNavigationMobileMenu } from './NavigationMobileMenuProvider'
import { FEATURE_FLAGS } from '@/feature-flags'

// Make sure that any link you add here is added to `SidebarLite`
const NavigationMobileMenu: React.FC = () => {
  const { isOpen, open, close } = useNavigationMobileMenu()

  const { isMediumScreen } = useMediumScreen()

  if (isMediumScreen) {
    return null
  }

  return (
    <>
      <UnstyledButton onClick={open}>
        <Icon name="menu" />
      </UnstyledButton>

      <Transition mounted={isOpen} transition="fade" duration={100} timingFunction="ease">
        {(styles) => (
          <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000 }} style={styles}>
            <Box pt="xl" pb="sm" px="sm" sx={(t) => ({ position: 'relative', background: t.colors.dark[5] })}>
              <Box sx={{ position: 'absolute', top: 24, right: 16 }}>
                <UnstyledButton onClick={close}>
                  <Icon name="cross" sx={(t) => ({ color: t.white })} />
                </UnstyledButton>
              </Box>

              <Stack align="center">
                <NavigationMobileMenuItem href="/">Liquid Staking</NavigationMobileMenuItem>
                <NavigationMobileMenuItem href="/airdrop">Airdrops</NavigationMobileMenuItem>
                <NavigationMobileMenuItem href="/earn">Earn</NavigationMobileMenuItem>
                <NavigationMobileMenuItem href="/sweeper">Dust Sweeper</NavigationMobileMenuItem>

                {FEATURE_FLAGS.STATUS_PAGE ? (
                  <NavigationMobileMenuItem href="/status">Status</NavigationMobileMenuItem>
                ) : (
                  <NavigationMobileMenuItem href={config.links.stake} external>
                    Stake STRD
                  </NavigationMobileMenuItem>
                )}

                <NavigationMobileMenuItem href={config.links.stats} external>
                  Stats
                </NavigationMobileMenuItem>
                <NavigationMobileMenuItem href={config.links.support} external>
                  Support
                </NavigationMobileMenuItem>
              </Stack>

              <Space h="lg" />

              <NavigationMobileMenuConnectWallet />
            </Box>
          </Box>
        )}
      </Transition>
    </>
  )
}

export { NavigationMobileMenu }
