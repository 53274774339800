import { convertDenomToMicroDenom } from '../utils'
import { INJ_CHAIN_INFO } from '@/config'
import { fatal } from '@/utils'
import { StdFee, encodeSecp256k1Pubkey } from '@cosmjs/amino'
import { EncodeObject, isOfflineDirectSigner } from '@cosmjs/proto-signing'
import {
  DeliverTxResponse,
  QueryClient,
  SignerData,
  setupAuthExtension,
  setupBankExtension,
  setupTxExtension,
  setupStakingExtension
} from '@cosmjs/stargate'
import {
  ChainRestTendermintApi,
  MsgTransfer,
  SIGN_AMINO,
  TxGrpcApi,
  TxRestClient,
  createTransaction,
  createTxRawEIP712,
  createWeb3Extension,
  getEip712TypedData,
  getTxRawFromTxRawOrDirectSignResponse
} from '@injectivelabs/sdk-ts'
import { EthereumChainId } from '@injectivelabs/ts-types'
import { BigNumberInBase, DEFAULT_BLOCK_TIMEOUT_HEIGHT } from '@injectivelabs/utils'
import { Buffer } from 'buffer'
import { z } from 'zod'
import Long from 'long'
import { Account } from '../types'
import { InjectiveIbcReturnType } from './signer-types'
import { Tendermint34Client } from '@cosmjs/tendermint-rpc'
import { Uint53 } from '@cosmjs/math'

const injectiveSupportedWallets = ['keplr-extension', 'leap-extension', 'cosmostation-extension']

const simulateInjectiveIbcTransaction = async (
  account: Account,
  address: string,
  messages: readonly EncodeObject[],
  memo: string | undefined
) => {
  const anyMsgs = messages.map((m) => account.client.registry.encodeAsAny(m))

  // We need this because it gives us a pubkey with the type we need
  const accountFromSigner = (await account.signer.getAccounts()).find((account) => {
    return account.address === address
  })

  if (!accountFromSigner) {
    throw new Error('Failed to retrieve account from signer')
  }

  const pubkey = encodeSecp256k1Pubkey(accountFromSigner.pubkey)

  const { sequence } = await account.client.getSequence(address)

  const tendermintClient = await Tendermint34Client.connect(INJ_CHAIN_INFO.rpc)

  const queryClient = QueryClient.withExtensions(
    tendermintClient,
    setupAuthExtension,
    setupBankExtension,
    setupStakingExtension,
    setupTxExtension
  )

  const { gasInfo } = await queryClient.tx.simulate(anyMsgs, memo, pubkey, sequence)

  if (!gasInfo) {
    throw new Error('Could not find gas info in simulation response')
  }

  return Uint53.fromString(gasInfo.gasUsed.toString()).toNumber()
}

const signInjectiveIbcTransaction = async (
  account: Account,
  _address: string,
  messages: EncodeObject[],
  fee: StdFee,
  memo: string,
  _explicitSignerData?: SignerData
): Promise<InjectiveIbcReturnType> => {
  // We currently do not have a use-case for a complex IBC transfer operation having multiple messages,
  // thus do not support it at the moment. Let's revisit if this is the case in the future.
  const message = messages[0]?.value

  if (message == null) {
    throw fatal('Unable to sign an ibc transaction without any messages.')
  }

  const { timeoutNumber, timeoutHeight } = await generateTimeoutValues()

  // This is important - if you leave this out, the transaction will fail.
  // Keplr will give you an ambiguous error that says "cannot access length property of undefined"
  const msgMemo = message.memo ?? 'IBC transfer from Injective to Stride'

  const msg: MsgTransfer = MsgTransfer.fromJSON({
    sender: message.sender,
    receiver: message.receiver,
    amount: {
      denom: message.token.denom,
      amount: message.token.amount
    },
    port: message.sourcePort,
    channelId: message.sourceChannel,
    timeout: message.timeoutTimestamp,
    height: {
      revisionNumber: timeoutHeight,
      revisionHeight: timeoutNumber
    },
    memo: msgMemo
  })

  const { accountNumber, sequence } = await account.client.getSequence(account.address)

  // We have a strong assumption that the default signing type is SIGN_DIRECT for non-Ledger transactions.
  if (isOfflineDirectSigner(account.signer)) {
    const { signDoc } = createTransaction({
      pubKey: Buffer.from(account.pubkey).toString('base64'),
      chainId: INJ_CHAIN_INFO.chainId,
      fee,
      memo: memo,
      message: msg,
      sequence,
      accountNumber
    })

    const directResponse = await account.signer.signDirect(account.address, {
      bodyBytes: signDoc.bodyBytes,
      authInfoBytes: signDoc.authInfoBytes,
      accountNumber: Long.fromString(signDoc.accountNumber),
      chainId: signDoc.chainId
    })

    return {
      type: 'injective',
      value: getTxRawFromTxRawOrDirectSignResponse(directResponse)
    }
  }

  const feeAmount = fee.amount[0]

  if (feeAmount == null) {
    throw fatal('Unable to sign an ibc transaction without a fee amount.')
  }

  const payloadFee = {
    amount: [
      {
        amount: String(convertDenomToMicroDenom(0.05, feeAmount.denom)),
        denom: feeAmount.denom
      }
    ],
    gas: fee.gas
  }

  const eip712TypedData = getEip712TypedData({
    msgs: [msg],
    tx: {
      accountNumber: accountNumber.toString(),
      sequence: sequence.toString(),
      chainId: INJ_CHAIN_INFO.chainId,
      timeoutHeight: timeoutHeight.toString()
    },
    fee: payloadFee,
    ethereumChainId: EthereumChainId.Mainnet
  })

  // @WARNING: This is a workaround because for some reason `msg.toEip712()`
  // which is used by `getEip712TypedData` removes the `memo` field. If it's
  // missing from the request, Keplr will give you an ambiguous error that says
  // "cannot access length property of undefined"
  eip712TypedData.message.msgs[0].value.memo = msgMemo

  const stdSignDoc = {
    chain_id: INJ_CHAIN_INFO.chainId,
    account_number: accountNumber.toString(),
    sequence: sequence.toString(),
    fee: payloadFee,
    msgs: [msg.toEip712()],
    memo,
    timeout_height: timeoutHeight.toString()
  }

  // @WARNING: This is a workaround because for some reason `msg.toEip712()`
  // removes the `memo` field. If it's missing from the request, Keplr will
  // give you an ambiguous error that says "cannot access length property of undefined"
  stdSignDoc.msgs[0].value.memo = msgMemo

  if (!injectiveSupportedWallets.includes(account.wallet.walletName))
    throw fatal(`Wallet type ${account.wallet.walletName} does not support Evmos signing.`)

  let walletInstance

  switch (account.wallet.walletName) {
    case 'keplr-extension':
      walletInstance = window.keplr
      break
    case 'leap-extension':
      walletInstance = window.leap
      break
    case 'cosmostation-extension':
      walletInstance = window.cosmostation.providers.keplr
      break
  }

  if (!walletInstance) throw fatal(`Could not find wallet with Evmos signing support.`)

  const aminoSignResponse = await walletInstance.experimentalSignEIP712CosmosTx_v0(
    INJ_CHAIN_INFO.chainId,
    account.address,
    eip712TypedData,
    stdSignDoc
  )

  const { txRaw } = createTransaction({
    pubKey: Buffer.from(account.pubkey).toString('base64'),
    chainId: INJ_CHAIN_INFO.chainId,
    signMode: SIGN_AMINO,
    memo: aminoSignResponse.signed.memo,
    fee: aminoSignResponse.signed.fee,
    message: msg,
    sequence: parseInt(aminoSignResponse.signed.sequence, 10),
    accountNumber: parseInt(aminoSignResponse.signed.account_number, 10),
    // @TODO: Consider throwing if timeout_height is not set
    timeoutHeight: parseInt(aminoSignResponse.signed.timeout_height ?? '0', 10)
  })

  const web3Extension = createWeb3Extension({ ethereumChainId: EthereumChainId.Mainnet })

  const txRawEip712 = createTxRawEIP712(txRaw, web3Extension)

  txRawEip712.signatures = [Buffer.from(aminoSignResponse.signature.signature, 'base64')]

  return {
    type: 'injective-ledger',
    value: txRawEip712
  }
}

const attributesSchema = z.array(
  z.object({
    key: z.string(),
    value: z.string(),
    index: z.boolean().optional()
  })
)

const eventSchema = z.array(
  z.object({
    type: z.string(),
    attributes: attributesSchema
  })
)

// - Broadcast
// - Poll transaction hash until it's included in a block
//
// @NOTE: Injective and Ledger is expected to fail, but we'll keep the code here (as it
// doesn't change behavior). Furthermore, we just need to do a bit of work, nudge
// a few parameters and we'll have Ledger support over the finish line. Signing above works
// amazing, but broadcasting below has some issues likely related to timeoutHeight or so:
// > Error: block height: 30869685, timeout height: 30869666: tx timeout height
// Bojan from Injective mentioned this is likely timeout_height being insufficient, but we
// should look into this further given that we are already following their docs to generate
// a good value for timeout_height. Refer to `generateTimeoutHeight` below
//
// @TODO: Implement standard tx error (via `assertTxSuccess`)
const broadcastInjectiveIbcTransaction = async (
  _account: Account,
  payload: InjectiveIbcReturnType
): Promise<DeliverTxResponse> => {
  if (payload.type === 'injective') {
    // @TODO: Check for BroadcastTxError like we're doing with `broadcastTx`
    const client = new TxRestClient(INJ_CHAIN_INFO.rest)
    const partial = await client.broadcast(payload.value)
    const response = await client.fetchTxPoll(partial.txHash)

    return {
      height: response.height,
      code: response.code,
      transactionHash: response.txHash,
      gasUsed: Number(response.gasUsed),
      rawLog: response.rawLog,
      gasWanted: Number(response.gasWanted),
      events: eventSchema.parse(response.events),
      // @TODO: I have no idea why evmos returns data as string, but it's possible we may just need to convert.
      data: [],
      // @TODO: Look into adding this to the response so we're consistent
      txIndex: 0,
      // @TODO: Look into adding this to the response so we're consistent
      msgResponses: []
    }
  }

  const api = new TxGrpcApi('https://grpc.injective.network')

  // @TODO: Check for BroadcastTxError like we're doing with `broadcastTx`
  // https://github.com/Stride-Labs/interface/issues/535
  const response = await api.broadcast(payload.value)

  return {
    height: Number(response.height),
    code: Number(response.code),
    transactionHash: response.txHash,
    gasUsed: Number(response.gasUsed),
    rawLog: response.rawLog,
    gasWanted: Number(response.gasWanted),
    // @TODO: Look into adding this to the response so we're consistent.
    // I have no idea why evmos returns data as string, but it's possible we may just need to convert.
    data: [],
    // @TODO: Look into adding this to the response so we're consistent
    txIndex: 0,
    // @TODO: Look into adding this to the response so we're consistent
    events: [],
    // @TODO: Look into adding this to the response so we're consistent
    msgResponses: []
  }
}

// Get a good timeout height number based on the latest blocks
const generateTimeoutValues = async () => {
  const tendermint = new ChainRestTendermintApi(INJ_CHAIN_INFO.rest)
  const latestBlock = await tendermint.fetchLatestBlock()
  const timeoutNumber = parseInt(latestBlock.header.version.block, 10)
  const timeoutHeight = new BigNumberInBase(latestBlock.header.height).plus(DEFAULT_BLOCK_TIMEOUT_HEIGHT * 5).toNumber()
  return { timeoutNumber, timeoutHeight }
}

export { simulateInjectiveIbcTransaction, broadcastInjectiveIbcTransaction, signInjectiveIbcTransaction }
